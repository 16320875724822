/**
 * @generated SignedSource<<939d52345c9979b28bf3b35046c2eed6>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type AttributionWindowState = "ATTRIBUTION_WINDOW_STATE_NULL" | "ATTRIBUTION_WINDOW_STATE_SET" | "ATTRIBUTION_WINDOW_STATE_UNKNOWN" | "%future added value";
export type MessageSettingsPage_SettingsUI_Query$variables = {
  companyId: string;
};
export type MessageSettingsPage_SettingsUI_Query$data = {
  readonly company: {
    readonly " $fragmentSpreads": FragmentRefs<"MessageSettingsPageFormDataFragment_company">;
  } | null;
};
export type MessageSettingsPage_SettingsUI_Query$rawResponse = {
  readonly company: {
    readonly __typename: "Company";
    readonly attributionSettings: {
      readonly emailClickWindow: {
        readonly value: number;
      };
      readonly emailViewWindow: {
        readonly attributionWindowState: AttributionWindowState;
        readonly value: number;
      };
      readonly filterMachineOpened: boolean;
      readonly textClickWindow: {
        readonly value: number;
      };
      readonly textViewWindow: {
        readonly attributionWindowState: AttributionWindowState;
        readonly value: number;
      };
    } | null;
    readonly contactCard: {
      readonly active: boolean;
      readonly companyUrl: {
        readonly value: string;
      };
      readonly displayName: string;
      readonly email: string;
      readonly id: string;
      readonly imageUrl: {
        readonly value: string;
      };
      readonly phones: ReadonlyArray<string>;
      readonly title: string;
    } | null;
    readonly country: string;
    readonly id: string;
  } | {
    readonly __typename: string;
    readonly id: string;
  } | null;
};
export type MessageSettingsPage_SettingsUI_Query = {
  rawResponse: MessageSettingsPage_SettingsUI_Query$rawResponse;
  response: MessageSettingsPage_SettingsUI_Query$data;
  variables: MessageSettingsPage_SettingsUI_Query$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "companyId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "companyId"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "value",
  "storageKey": null
},
v3 = [
  (v2/*: any*/)
],
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v5 = [
  (v2/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "attributionWindowState",
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "MessageSettingsPage_SettingsUI_Query",
    "selections": [
      {
        "alias": "company",
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "MessageSettingsPageFormDataFragment_company"
              }
            ],
            "type": "Company",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "MessageSettingsPage_SettingsUI_Query",
    "selections": [
      {
        "alias": "company",
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "country",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "CompanyContactCard",
                "kind": "LinkedField",
                "name": "contactCard",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "phones",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "active",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "displayName",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Url",
                    "kind": "LinkedField",
                    "name": "imageUrl",
                    "plural": false,
                    "selections": (v3/*: any*/),
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "title",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Url",
                    "kind": "LinkedField",
                    "name": "companyUrl",
                    "plural": false,
                    "selections": (v3/*: any*/),
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "email",
                    "storageKey": null
                  },
                  (v4/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "AttributionSettings",
                "kind": "LinkedField",
                "name": "attributionSettings",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "AttributionWindow",
                    "kind": "LinkedField",
                    "name": "emailClickWindow",
                    "plural": false,
                    "selections": (v3/*: any*/),
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "AttributionWindow",
                    "kind": "LinkedField",
                    "name": "emailViewWindow",
                    "plural": false,
                    "selections": (v5/*: any*/),
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "filterMachineOpened",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "AttributionWindow",
                    "kind": "LinkedField",
                    "name": "textClickWindow",
                    "plural": false,
                    "selections": (v3/*: any*/),
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "AttributionWindow",
                    "kind": "LinkedField",
                    "name": "textViewWindow",
                    "plural": false,
                    "selections": (v5/*: any*/),
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "type": "Company",
            "abstractKey": null
          },
          (v4/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "a5523bc4fa033147e8fff13c70519829",
    "id": null,
    "metadata": {},
    "name": "MessageSettingsPage_SettingsUI_Query",
    "operationKind": "query",
    "text": "query MessageSettingsPage_SettingsUI_Query(\n  $companyId: ID!\n) {\n  company: node(id: $companyId) {\n    __typename\n    ... on Company {\n      ...MessageSettingsPageFormDataFragment_company\n    }\n    id\n  }\n}\n\nfragment MessageSettingsPageFormDataFragment_company on Company {\n  country\n  contactCard {\n    phones\n    active\n    displayName\n    imageUrl {\n      value\n    }\n    title\n    companyUrl {\n      value\n    }\n    email\n    id\n  }\n  attributionSettings {\n    emailClickWindow {\n      value\n    }\n    emailViewWindow {\n      value\n      attributionWindowState\n    }\n    filterMachineOpened\n    textClickWindow {\n      value\n    }\n    textViewWindow {\n      value\n      attributionWindowState\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "4ffe93f611d6a450b62651313c9d77a3";

export default node;
